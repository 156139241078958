:root {
  --tagify-dd-color-primary: rgb(53,149,246);
  --tagify-dd-bg-color: white; }

.tagify {
  --tags-border-color: #DDD;
  --tags-hover-border-color: #CCC;
  --tags-focus-border-color: #3595f6;
  --tag-bg: #E5E5E5;
  --tag-hover: #D3E2E2;
  --tag-text-color: black;
  --tag-text-color--edit: black;
  --tag-pad: 0.3em 0.5em;
  --tag-inset-shadow-size: 1.1em;
  --tag-invalid-color: #D39494;
  --tag-invalid-bg: rgba(211, 148, 148, 0.5);
  --tag-remove-bg: rgba(211, 148, 148, 0.3);
  --tag-remove-btn-bg: none;
  --tag-remove-btn-bg--hover: #c77777;
  --tag--min-width: 1ch;
  --tag--max-width: auto;
  --tag-hide-transition: .3s;
  --placeholder-color: black;
  --loader-size: .8em;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #DDD;
  border: 1px solid var(--tags-border-color);
  padding: 0;
  line-height: 1.1;
  cursor: text;
  outline: none;
  position: relative;
  transition: .1s; }

@-webkit-keyframes tags--bump {
  30% {
    transform: scale(1.2); } }

@keyframes tags--bump {
  30% {
    transform: scale(1.2); } }

@-webkit-keyframes rotateLoader {
  to {
    transform: rotate(1turn); } }

@keyframes rotateLoader {
  to {
    transform: rotate(1turn); } }
  .tagify:hover {
    border-color: #CCC;
    border-color: var(--tags-hover-border-color); }
  .tagify.tagify--focus {
    transition: 0s;
    border-color: #3595f6;
    border-color: var(--tags-focus-border-color); }
  .tagify[readonly] {
    cursor: default; }
    .tagify[readonly] > .tagify__input {
      visibility: hidden;
      width: 0;
      margin: 5px 0; }
    .tagify[readonly] .tagify__tag__removeBtn {
      display: none; }
    .tagify[readonly] .tagify__tag > div {
      padding: 0.3em 0.5em;
      padding: var(--tag-pad); }
      .tagify[readonly] .tagify__tag > div::before {
        background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
        box-shadow: none;
        filter: brightness(0.95); }
  .tagify--loading .tagify__input::before {
    content: none; }
  .tagify--loading .tagify__input::after {
    content: '';
    vertical-align: middle;
    opacity: 1;
    width: .7em;
    height: .7em;
    width: var(--loader-size);
    height: var(--loader-size);
    border: 3px solid;
    border-color: #EEE #BBB #888 transparent;
    border-radius: 50%;
    -webkit-animation: rotateLoader .4s infinite linear;
            animation: rotateLoader .4s infinite linear;
    margin: -2px 0 -2px .5em; }
  .tagify--loading .tagify__input:empty::after {
    margin-left: 0; }
  .tagify + input,
  .tagify + textarea {
    display: none !important; }
  .tagify__tag {
    display: inline-flex;
    align-items: center;
    margin: 5px 0 5px 5px;
    position: relative;
    z-index: 1;
    outline: none;
    cursor: default;
    transition: .13s ease-out; }
    .tagify__tag > div {
      vertical-align: top;
      box-sizing: border-box;
      max-width: 100%;
      padding: 0.3em 0.5em;
      padding: var(--tag-pad);
      color: black;
      color: var(--tag-text-color);
      line-height: inherit;
      border-radius: 3px;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      transition: .13s ease-out; }
      .tagify__tag > div > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        min-width: var(--tag--min-width);
        max-width: var(--tag--max-width);
        transition: .8s ease, .1s color; }
        .tagify__tag > div > *[contenteditable] {
          outline: none;
          -webkit-user-select: text;
             -moz-user-select: text;
              -ms-user-select: text;
                  user-select: text;
          cursor: text;
          margin: -2px;
          padding: 2px;
          max-width: 350px; }
      .tagify__tag > div::before {
        content: '';
        position: absolute;
        border-radius: inherit;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        pointer-events: none;
        transition: 120ms ease;
        -webkit-animation: tags--bump .3s ease-out 1;
                animation: tags--bump .3s ease-out 1;
        box-shadow: 0 0 0 1.1em #E5E5E5 inset;
        box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-bg) inset; }
    .tagify__tag:hover:not([readonly]) div::before {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      box-shadow: 0 0 0 1.1em #D3E2E2 inset;
      box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-hover) inset; }
    .tagify__tag--loading {
      pointer-events: none; }
      .tagify__tag--loading .tagify__tag__removeBtn {
        display: none; }
      .tagify__tag--loading::after {
        --loader-size:  .4em;
        content: '';
        vertical-align: middle;
        opacity: 1;
        width: .7em;
        height: .7em;
        width: var(--loader-size);
        height: var(--loader-size);
        border: 3px solid;
        border-color: #EEE #BBB #888 transparent;
        border-radius: 50%;
        -webkit-animation: rotateLoader .4s infinite linear;
                animation: rotateLoader .4s infinite linear;
        margin: 0 .5em 0 -.1em; }
    .tagify__tag.tagify--noAnim > div::before {
      -webkit-animation: none;
              animation: none; }
    .tagify__tag.tagify--hide {
      width: 0 !important;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      opacity: 0;
      transform: scale(0);
      transition: .3s;
      transition: var(--tag-hide-transition);
      pointer-events: none; }
    .tagify__tag.tagify--mark div::before {
      -webkit-animation: none;
              animation: none; }
    .tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
      opacity: .5; }
    .tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
      box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.5) inset !important;
      box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-invalid-bg) inset !important;
      transition: .2s; }
    .tagify__tag[readonly] .tagify__tag__removeBtn {
      display: none; }
    .tagify__tag[readonly] > div::before {
      background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
      box-shadow: none;
      filter: brightness(0.95); }
    .tagify__tag--editable > div {
      color: black;
      color: var(--tag-text-color--edit); }
      .tagify__tag--editable > div::before {
        box-shadow: 0 0 0 2px #D3E2E2 inset !important;
        box-shadow: 0 0 0 2px var(--tag-hover) inset !important; }
    .tagify__tag--editable.tagify--invalid > div::before {
      box-shadow: 0 0 0 2px #D39494 inset !important;
      box-shadow: 0 0 0 2px var(--tag-invalid-color) inset !important; }
  .tagify__tag__removeBtn {
    order: 5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    font: 14px Serif;
    background: none;
    background: var(--tag-remove-btn-bg);
    color: black;
    color: var(--tag-text-color);
    width: 14px;
    height: 14px;
    margin-right: 4.66667px;
    margin-left: -4.66667px;
    transition: .2s ease-out; }
    .tagify__tag__removeBtn::after {
      content: "\00D7"; }
    .tagify__tag__removeBtn:hover {
      color: white;
      background: #c77777;
      background: var(--tag-remove-btn-bg--hover); }
      .tagify__tag__removeBtn:hover + div > span {
        opacity: .5; }
      .tagify__tag__removeBtn:hover + div::before {
        box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.3) inset !important;
        box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-remove-bg) inset !important;
        transition: .2s; }
  .tagify:not(.tagify--mix) .tagify__input br {
    display: none; }
  .tagify:not(.tagify--mix) .tagify__input * {
    display: inline;
    white-space: nowrap; }
  .tagify__input {
    display: block;
    min-width: 110px;
    margin: 5px;
    padding: 0.3em 0.5em;
    padding: var(--tag-pad, 0.3em 0.5em);
    line-height: inherit;
    position: relative;
    white-space: pre-line;
    /* Seems firefox newer versions don't need this any more
        @supports ( -moz-appearance:none ){
            &::before{
                line-height: inherit;
                position:relative;
            }
        }
*/
    /*
            in "mix mode" the tags are inside the "input" element
        */ }
    .tagify__input::before {
      display: inline-block;
      width: 0; }
    @supports (-moz-appearance: none) {
      .tagify__input:empty {
        display: flex; } }
    .tagify__input:empty::before {
      transition: .2s ease-out;
      opacity: 0.5;
      transform: none;
      width: auto; }
    .tagify__input:focus {
      outline: none; }
      .tagify__input:focus::before {
        transition: .2s ease-out;
        opacity: 0;
        transform: translatex(6px);
        /* ALL MS BROWSERS: hide placeholder (on focus) otherwise the caret is places after it, which is weird */
        /* IE10+ CSS styles go here */
        /* IE Edge 12+ CSS styles go here */ }
        @supports (-moz-appearance: none) {
          .tagify__input:focus::before {
            display: none; } }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .tagify__input:focus::before {
            display: none; } }
        @supports (-ms-ime-align: auto) {
          .tagify__input:focus::before {
            display: none; } }
      .tagify__input:focus:empty::before {
        transition: .2s ease-out;
        opacity: 0.3;
        transform: none; }
        @supports (-moz-appearance: none) {
          .tagify__input:focus:empty::before {
            display: inline-block; } }
    .tagify__input::before {
      content: attr(data-placeholder);
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1em;
      line-height: 1em;
      margin: auto 0;
      z-index: 1;
      color: black;
      color: var(--placeholder-color);
      white-space: nowrap;
      pointer-events: none;
      opacity: 0; }
      .tagify--mix .tagify__input::before {
        position: static;
        line-height: inherit; }
    .tagify__input::after {
      content: attr(data-suggest);
      display: inline-block;
      white-space: pre;
      /* allows spaces at the beginning */
      color: black;
      opacity: .3;
      pointer-events: none;
      max-width: 100px; }
    .tagify__input .tagify__tag {
      margin: 0; }
      .tagify__input .tagify__tag > div {
        padding-top: 0;
        padding-bottom: 0; }
  .tagify--mix {
    line-height: 1.7; }
    .tagify--mix .tagify__input {
      padding: 5px;
      margin: 0;
      width: 100%;
      height: 100%;
      line-height: inherit; }
      .tagify--mix .tagify__input::before {
        height: auto; }
      .tagify--mix .tagify__input::after {
        content: none; }
  .tagify--select::after {
    content: '>';
    opacity: .5;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    font: 16px monospace;
    line-height: 8px;
    height: 8px;
    pointer-events: none;
    transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
    transition: .2s ease-in-out; }
  .tagify--select[aria-expanded=true]::after {
    transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2); }
  .tagify--select .tagify__tag {
    position: absolute;
    top: 0;
    right: 1.8em;
    bottom: 0; }
    .tagify--select .tagify__tag div {
      display: none; }
  .tagify--select .tagify__input {
    width: 100%; }
  .tagify--invalid {
    --tags-border-color: #D39494; }
  .tagify__dropdown {
    position: absolute;
    z-index: 9999;
    transform: translateY(1px);
    overflow: hidden;
    /* CURRENTLY NOT USED
        &__addNewBtn{
          width: 1.22em;
          height: 1.22em;
          line-height: 1;
          font-size: 1.2em;
          border-radius: 50%;
          text-align: center;
          background: $tags-focus-border-color;
          background: var(--tagify-dd-color-primary);
          color: white;
          color: var(--tagify-dd-bg-color);
          border: none;
          cursor: pointer;
        }
        */ }
    .tagify__dropdown[placement="top"] {
      margin-top: 0;
      transform: translateY(-2px); }
      .tagify__dropdown[placement="top"] .tagify__dropdown__wrapper {
        border-top-width: 1px;
        border-bottom-width: 0; }
    .tagify__dropdown--text {
      box-shadow: 0 0 0 3px rgba(var(--tagify-dd-color-primary), 0.1);
      font-size: .9em; }
      .tagify__dropdown--text .tagify__dropdown__wrapper {
        border-width: 1px; }
    .tagify__dropdown__wrapper {
      max-height: 300px;
      overflow: hidden;
      background: white;
      background: var(--tagify-dd-bg-color);
      border: 1px solid #3595f6;
      border-color: var(--tagify-dd-color-primary);
      border-top-width: 0;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
      transition: 0.25s cubic-bezier(0, 1, 0.5, 1); }
      .tagify__dropdown__wrapper:hover {
        overflow: auto; }
    .tagify__dropdown--initial .tagify__dropdown__wrapper {
      max-height: 20px;
      transform: translateY(-1em); }
    .tagify__dropdown--initial[placement="top"] .tagify__dropdown__wrapper {
      transform: translateY(2em); }
    .tagify__dropdown__item {
      box-sizing: inherit;
      padding: 0.3em 0.5em;
      margin: 1px;
      cursor: pointer;
      border-radius: 2px;
      position: relative;
      outline: none; }
      .tagify__dropdown__item--active {
        background: #3595f6;
        background: var(--tagify-dd-color-primary);
        color: white; }
      .tagify__dropdown__item:active {
        filter: brightness(105%); }
